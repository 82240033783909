body {
    font-family: 'Montserrat', sans-serif;
    background-color: #0c0c0e;
}

.overflow-hidden {
    overflow: hidden;
}

* {
    outline: none;

    &::selection {
        background: shade($primary-color, 10%);
    }
}

.wrapper {
    margin: 0 auto;
    flex-wrap: wrap;

    @include media-breakpoint-down(lg) {
        width: 100%;
    }
    @include media-breakpoint-between(lg, xl) {
        width: 900px;
    }
    @include media-breakpoint-up(xl) {
        width: 1140px;
    }
}
