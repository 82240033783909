.introduction {
    padding: 5rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4rem;

    @media (max-width: 1248px) {
        gap: 6rem;
    }

    &__header {
        color: #fff;
        font-size: 2rem;

        h3 {
            background: #e04000;
            width: fit-content;
            padding: 10px 20px;
            font-weight: 600;
        }
    }

    &__section {
        display: flex;
        align-items: center;
        gap: 4rem;

        @media (max-width: 768px) {
            flex-direction: column;
            gap: 1rem;
            padding: 0 1rem;
            box-sizing: border-box;
        }

        &:nth-child(even) {
            flex-direction: row-reverse;

            @media (max-width: 768px) {
                flex-direction: column;
            }

            p {
                &:after {
                    right: -21px;
                    border-right: 20px solid transparent;
                    left: unset;
                    border-left: unset;
                }
            }
        }

        img {
            width: 460px;
            height: 360px;
            object-fit: contain;

            @media (max-width: 1248px) {
                width: 310px;
                height: 260px;
            }

            @media (max-width: 576px) {
                width: 100%;
            }
        }

        p {
            font-size: 1.2rem;
            color: #fff;
            letter-spacing: 2px;
            line-height: 1.9rem;
            flex-grow: 1;
            width: 100px;
            font-weight: 100;
            border: 1px solid #ffffff0d;
            background: #0f1012;
            padding: 2rem;
            position: relative;

            @media (max-width: 768px) {
                width: unset;
            }

            &:after {
                content: "";
                width: 0;
                height: 0;
                border-top: 20px solid rgba(255, 255, 255, 0.0509803922);
                border-left: 20px solid transparent;
                position: absolute;
                top: -1px;
                left: -21px;

                @media (max-width: 768px) {
                    display: none;
                }
            }
        }
    }
}