.paralax {
  min-height: 600px;
  // background-attachment: fixed;
  // background-position: center;
  // background-repeat: no-repeat;
  // background-size: cover;
  width: 100%;
  max-width: 100%;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  position: static;

  @include media-breakpoint-down(lg) {
    width: 100vw;
    background-attachment: initial;
  }
}
