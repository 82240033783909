.scroll-to-top {
    background: #e04000;
    position: fixed;
    right: 30px;
    bottom: 30px;
    width: 50px;
    height: 50px;
    color: #0c0c0e;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 25px;
    z-index: 50;
    cursor: pointer;
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.5s, color 0.5s, background 0.5s linear;

    &--visible {
        visibility: visible;
        opacity: 1;
    }

    &:hover {
        background: #0c0c0e;
        color: #e04000;
    }
}
