$primary-color: #e04000;
$secondary-color: #282c34;
$accent-color: #ecf0f5;

$facebook-color: #3B5998;
$twitter-color: #00aced;
$google-plus-color: #d34836;
$linkedin-color: #0077B5;

$success-color: #008000;
$danger-color: #FF0000;
