.footer {
    padding: 3rem 0;
    color: #fff;

    &--second {
        background: #0f1012;
        padding: 10px 0;
    }

    &__wrapper {
        display: flex;

        @include media-breakpoint-down(sm) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
        }
        @include media-breakpoint-up(md) {
            justify-content: space-between;
        }
        @include media-breakpoint-between(md, lg) {
            padding: 0 20px;
            box-sizing: border-box;
        }
    }

    &__img {
        @include media-breakpoint-down(sm) {
            max-width: 300px;
            margin: 10px 0;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                display: block;
            }
        }
        @include media-breakpoint-up(md) {
            max-width: 300px;
            margin: 10px 0;
            display: flex;
            align-items: center;

            img {
                width: 100%;
                display: block;
            }
        }
    }

    &__copyright {
        display: flex;
        align-items: center;

        @include media-breakpoint-down(sm) {
            margin: 10px;
            text-align: center;
        }

        p {
            font-size: 15px;
        }
    }

    &__social {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        font-size: 15px;

        @include media-breakpoint-down(sm) {
            margin: 10px 0;
        }

        a {
            color: #fff;
            font-size: 20px;
            transition: all 0.5s;
            width: 38px;
            text-align: center;
            text-shadow: 0 0 5px #000;

            &:first-of-type {
                margin: 0 0 0 10px;
            }

            &:hover > .fa-facebook-f {
                color: $facebook-color;
            }

            &:hover > .fa-linkedin-in {
                color: $linkedin-color;
            }

            .fab {
                transition: all 0.5s;
            }
        }
    }

    .footer-informations {
        display: flex;
        margin: 10px 0;
        flex-wrap: wrap;
        flex-grow: 1;
        gap: 3rem;

        @include media-breakpoint-down(sm) {
            justify-content: center;
            flex-direction: column;
            align-items: center;
            gap: 2rem;
        }

        &__address, &__register {
            font-size: 16px;
            display: flex;
            flex-direction: column;
            margin: 0;

            @include media-breakpoint-down(sm) {
                width: 100%;
            }

            h2 {
                margin: 0 0 10px;
                font-weight: 500;
            }

            p {
                margin: 0 0 5px;
                font-weight: 100;
            }
        }

        &__data {
            display: flex;
            flex-direction: column;
            max-width: 300px;
            gap: 3px;

            p {
                font-weight: 500;
                margin: 0;
            }

            p:nth-child(4), p:nth-child(5), p:nth-child(6) {
                font-size: 12px;
                font-weight: 100;
                line-height: 15px;
            }
        }
    }
}
