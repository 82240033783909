@import
    'base/animate',
    'base/reset';

@import
    'helpers/variables',
    'helpers/mixins',
    'helpers/functions';

@import
    'vendors/boostrap-functions',
    'vendors/boostrap-variables',
    'vendors/boostrap-breakpoints';

@import
    'modules/notify',
    'modules/parallax',
    'modules/scroll_to_top',
    'modules/buttons';

@import
    'partials/header',
    'partials/introduction',
    'partials/ai',
    'partials/testimonials',
    'partials/clients',
    'partials/projects',
    'partials/offer',
    'partials/products',
    'partials/contact',
    'partials/map',
    'partials/footer',
    'partials/privacy_policy';

@import
    'pages/index';

@import
    'vendors/slick',
    'vendors/slick-theme';

::-webkit-scrollbar {
    background: shade($secondary-color, 30%);
    width: 8px;
}
::-webkit-scrollbar-thumb {
    background: $primary-color;
}
::-webkit-scrollbar-corner {
    background: shade($secondary-color, 30%);
}
