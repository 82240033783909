.header {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

    &--top-only {
        height: 180px;
    }

    &__top {
        position: absolute;
        top: 3rem;
        left: 0;
        right: 0;
        display: flex;
        justify-content: space-between;
        max-width: 1800px;
        margin: 0 auto;
        padding: 0 2rem;

        @media (max-width: 992px) {
            padding: 0 1rem;
            overflow: hidden;
        }

        > img {
            width: 250px;
            height: 71px;
        }

        nav {
            display: flex;
            align-items: center;

            @media (max-width: 992px) {
                align-items: flex-start;
                transform: translateX(83%);
                transition: transform 0.5s;
                z-index: 50;
                position: fixed;
                right: 18px;
            }

            &.active {
                @media (max-width: 992px) {
                    transform: translateX(8%);
                }
            }

            ul {
                display: flex;
                gap: 3rem;
                align-items: center;

                @media (max-width: 992px) {
                    padding: 2rem;
                    align-items: flex-end;
                    gap: 2rem;
                    flex-direction: column;
                    background-color: #e04000;
                    border-bottom-left-radius: 10px;
                }

                li {
                    a {
                        color: #fff;
                        text-decoration: none;
                        transition: color 0.5s;

                        &:hover {
                            color: #e04000;

                            @media (max-width: 992px) {
                                color: #fff;
                            }
                        }
                    }

                    &.lang-icon {
                        display: flex;
                        gap: 1rem;

                        img {
                            width: 30px;
                            height: 30px;
                            border-radius: 50%;
                            border: 3px solid #0f1012;
                            cursor: pointer;
                            transition: border 0.5s;

                            &:hover {
                                border: 3px solid #e04000;
                            }
                        }
                    }
                }
            }

            .hamburger {
                display: none;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                color: #fff;
                font-size: 2em;
                background-color: #e04000;
                border-top-left-radius: 10px;
                border-bottom-left-radius: 10px;

                @media (max-width: 992px) {
                    display: flex;
                    padding: 1rem;
                }
            }
        }
    }

    &__main {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;

        @media (max-width: 1248px) {
            width: 100%;
        }

        svg {
            max-height: 60vh;
        }

        h1 {
            color: #fff;
            font-optical-sizing: auto;
            font-weight: 600;
            font-style: normal;
            z-index: 2;
            margin: 0 auto;
            font-size: 70px;
            position: absolute;

            @media (max-width: 1248px) {
                margin: 0 1rem;
            }

            @media (max-width: 768px) {
                font-size: 35px;
            }

            span {
                color: #e04000;
            }
        }
    }

    &__background-text {
        width: 100%;
        height: 100vh;
        font-weight: bold;
        font-size: 12px;
        position: absolute;
        overflow: hidden;
        z-index: 1;

        > div {
            font-family: "Anonymous Pro", serif;
            animation: come2life linear 10s infinite;
            transform-origin: center center;
            opacity: 0;
            width: 200px;
            height: 200px;
            position: absolute;
            backface-visibility: hidden;
            color: #ffffff1c;

            img {
                width: 200px;
                opacity: 0.5;
            }

            &:nth-child(1) {
                left: 30vw;
                top: 30vh;
                animation-delay: 0s;
            }

            &:nth-child(2) {
                left: 70vw;
                top: 30vh;
                animation-delay: 4s;
            }

            &:nth-child(3) {
                left: 50vw;
                top: 50vh;
                animation-delay: 8s;
            }

            &:nth-child(4) {
                left: 30vw;
                top: 70vh;
                animation-delay: 6s;
            }

            &:nth-child(5) {
                left: 70vw;
                top: 70vh;
                animation-delay: 2s;
            }
        }
    }
}