.map {
    .mapouter {
        text-align: right;
        height: 450px;
        width: 100%;
    }

    .gmap_canvas {
        overflow: hidden;
        background: none !important;
        height: 450px;
        width: 100%;
    }
}
