[data-notify="icon"] {
  margin-right: 5px;
}

[data-notify="container"] {
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  border-radius: 0;
  padding: 15px 40px 15px 15px;
}

[data-notify="container"].alert-success {
  background: $success-color;
  border: 1px solid shade($success-color, 10%);
  border-left: 5px solid shade($success-color, 10%);
}

[data-notify="container"].alert-danger {
  background: $danger-color;
  border: 1px solid shade($danger-color, 20%);
  border-left: 5px solid shade($danger-color, 20%);
}

[data-notify="dismiss"] {
  color: #fff;
  text-shadow: 0 1px 0 #000;
  font-weight: 100;
  font-size: 18px;
  background: none;
  border: none;
  cursor: pointer;
  opacity: 0.2;
  transition: opacity 0.5s;

  &:hover {
    opacity: 1;
  }
}
