.products {
  background: $secondary-color;
  color: #fff;
  position: relative;
  font-family: Montserrat, sans-serif;

  &:before {
    position: absolute;
    top: -50px;
    content: "";
    background: $secondary-color;
    transform: skewY(3deg);
    height: 100px;
    width: 100%;
    z-index: 20;
    display: block;
  }

  &:after {
    position: absolute;
    bottom: -50px;
    content: "";
    background: $secondary-color;
    transform: skewY(3deg);
    height: 100px;
    width: 100%;
    z-index: 20;
    display: block;
  }

  &__slider {
    width: 100%;
  }

  &__wrapper {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    position: relative;
    z-index: 30;
    padding: 0 0 40px;

    @include media-breakpoint-down(sm) {
      flex-direction: column;
    }
    @include media-breakpoint-between(md, lg) {
      padding: 0 20px;
      box-sizing: border-box;
    }

    h3 {
      font-size: 30px;
      width: 100%;
      margin: 30px 0 60px;
      padding: 20px 0;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .line {
        width: 85%;
        height: 1px;
        position: absolute;
        background: transparentize(#fff, 0.8);
        z-index: 2;
      }

      .text {
        position: absolute;
        background: $secondary-color;
        z-index: 3;
        padding: 0 20px;
      }
    }
  }

  &__item {
    display: flex !important;
    flex-wrap: wrap;
    width: 100%;
    margin: 20px 0;
  }

  .item {

    &__preview {
      width: 400px;
      padding: 20px;
      flex-grow: 1;
      box-sizing: border-box;

      img {
        display: block;
        margin: 0 auto;
        max-width: 100%;
        max-height: 450px;
      }
    }

    &__container {
      width: 400px;
      flex-grow: 2;
      font-size: 18px;
      display: flex;
      flex-direction: column;
      padding: 20px;

      h4 {
        font-size: 25px;
        font-weight: 600;
        text-transform: uppercase;
        border-left: 3px solid $primary-color;
        padding: 8px 0 8px 14px;
        width: 100%;
        width: -webkit-fill-available;
      }
    }

    &__text {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 100%;

      p {
        line-height: 25px;
        margin: 50px 0 20px;
      }
    }

    &__button {
      background: $secondary-color;
      color: #fff;
      border: 2px solid #fff;
      padding: 10px 15px;
      display: block;
      text-transform: uppercase;
      cursor: pointer;
      transition: all 0.5s;
      font-weight: 600;
      text-decoration: none;
      font-size: 13px;
      margin: 20px;
      display: block;
      width: fit-content;
      transition: all 0.5s;

      &:hover {
        border: 2px solid $primary-color;
        background: $primary-color;
        color: #fff;
      }
    }
  }
}
