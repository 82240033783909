.offer {
    color: #fff;
    position: relative;
    font-family: Montserrat, sans-serif;
    padding: 5rem 0;
    display: flex;
    align-items: center;
    background-color: #0f1012;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        z-index: 30;
        gap: 2rem;
        row-gap: 4rem;

        @media (max-width: 992px) {
            flex-direction: column;
        }

        h3 {
            font-size: 30px;
            width: 100%;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
        }
    }

    &__icons {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 20px;

        @media (max-width: 992px) {
            display: flex;
            align-items: center;
            gap: 20px;
            flex-wrap: wrap;
            justify-content: center;
        }
    }

    &__item {
        font-size: 50px;
        width: 100px;
        height: 100px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #fff;
        transition: all 0.5s;

        img {
            width: 100%;
        }

        &--dotnet {
            flex-direction: column;
            font-family: inherit;

            p:nth-child(1) {
                font-size: 15px;
                padding: 0 0 0 10px;
            }

            p:nth-child(2) {
                font-size: 35px;
            }
        }

        &--active {
            color: $primary-color;
        }

        &--active.offer__item--dotnet::before {
            background-color: $primary-color;
        }
    }

    &__text {
        font-size: 24px;
        flex-grow: 1;
        padding: 20px;
        line-height: 34px;
        letter-spacing: 1px;
        font-weight: 100;
        width: 100px;

        @media (max-width: 576px) {
            font-size: 18px;
            line-height: 28px;
            width: unset;
        }

        @media (max-width: 992px) {
            width: unset;
        }
    }

    @media (max-width: 768px) {
        padding: 3rem 0;
    }
}