.testimonials {
    position: relative;
    padding: 5rem 0;
    display: flex;
    align-items: center;

    &__wrapper {
        z-index: 30;
        position: relative;
        color: #fff;

        h3 {
            font-size: 30px;
            text-transform: uppercase;
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            width: fit-content;
            font-weight: 600;
            margin: 0 auto;
        }
    }

    &__container {
        .testimonial {
            display: flex;
            flex-direction: column;
            margin: 1rem;

            &__img {
                width: 150px;
                height: 150px;
                border-radius: 50%;
                background-color: #0c0c0e;
                border: 10px solid #0c0c0e;
                display: flex;
                align-items: center;
                overflow: hidden;
                margin: 0 auto;
                position: relative;
                top: 75px;
                z-index: 1;

                img {
                    width: 100%;
                    object-fit: cover;
                    filter: brightness(0) invert(1);
                }
            }

            &__text {
                display: flex;
                text-align: center;
                flex-direction: column;
                position: relative;
                justify-content: center;
                flex-grow: 1;
                background-color: #0f1012;
                height: 400px;

                p {
                    font-size: 15px;
                    line-height: 22px;
                    padding: 90px 2rem 4rem;

                    i {
                        color: #e04000;
                        position: relative;

                        &:nth-child(1) {
                            top: -5px;
                            right: 5px;
                        }

                        &:nth-child(2) {
                            bottom: -5px;
                            left: 5px;
                        }
                    }
                }

                span {
                    position: absolute;
                    right: 2rem;
                    bottom: 2rem;
                    color: #e04000;
                    font-weight: 500;
                    font-size: 1.2rem;
                }
            }
        }
    }

    &__more {
        display: flex;
        justify-content: flex-end;
        margin: 4rem 1rem 0 0;

        a {
            text-decoration: none;
            color: #fff;
            border: 2px solid #fff;
            padding: 15px;
            transition: background 0.5s;

            &:hover {
                background: $primary-color;
                border: 2px solid $primary-color;
            }

            .far {
                font-size: 20px;
                margin: 0 5px 0 0;
            }
        }
    }
}

.slider {
    &__button {
        position: absolute;
        top: 50%;
        z-index: 33;
        color: #fff;
        background: none;
        border: none;
        width: 30px;
        height: 30px;
        cursor: pointer;
        font-size: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        border-radius: 50%;
        transition: all 0.5s;

        &--prev {
            left: 0;
        }

        &--next {
            right: 0;
        }

        &:hover {
            background: #fff;
            color: #2c2b2a;
        }
    }

    &__squares {
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 33;

        .slider-square {
            width: 10px;
            height: 10px;
            background-color: #fff;
            margin: 5px;
            cursor: pointer;

            &--active {
                width: 15px;
                height: 15px;
                background-color: $primary-color;
            }
        }
    }
}

.slide {
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.5s;
    z-index: 0;

    &--active {
        opacity: 1;
        z-index: 30;
        visibility: visible;
    }
}
