.buttons-panel {
    display: flex;
    position: fixed;
    right: 80px;
    bottom: 30px;
}

.button {
    font-family: 'Montserrat', sans-serif;
    background: $primary-color;
    color: #fff;
    border: 2px solid $primary-color;
    padding: 10px 15px;
    display: block;
    text-transform: uppercase;
    cursor: pointer;
    transition: all 0.5s;
    font-weight: 600;
    text-decoration: none;
    font-size: 13px;
    margin: 0 10px;

    &:hover {
        background: #fff;
        color: $primary-color;
    }

    &--submit {
        border: 2px solid #fff;

        &:hover {
            background: #fff;
            color: $primary-color;
            border: 2px solid #fff;
        }
    }

    &--contact {
        font-size: 18px;
        padding: 10px 30px;
        margin: 10px 0 0;
        display: flex;
        align-items: center;
    }

    &--item {
        width: 50px;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        font-size: 16px;
    }
}
