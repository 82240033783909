.contact {
    background: $primary-color;
    padding: 5rem 0;
    position: relative;

    &__wrapper {
        display: flex;
        z-index: 30;
        position: relative;

        @include media-breakpoint-down(sm) {
            justify-content: center;
        }
    }

    &__info {
        max-width: 300px;
        width: -webkit-fill-available;
        flex-grow: 1;
        margin: 20px;
        color: #fff;
        display: flex;
        flex-direction: column;
        justify-content: center;

        @include media-breakpoint-down(sm) {
            h2 {
                text-align: center;
            }

            ul {
                li {
                    justify-content: center;
                }
            }
        }

        h2 {
            font-size: 30px;
            margin: 0 0 20px;
            font-weight: 600;
            text-transform: uppercase;
        }

        ul {
            font-size: 24px;

            li {
                margin: 10px 0;
                font-weight: 200;
                display: flex;
                align-items: center;

                span {
                    margin: 0 10px;
                    font-size: 30px;
                }

                a {
                    text-decoration: none;
                    color: #fff;
                }
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        width: 374px;
        flex-grow: 2;
        margin: 20px;

        @include media-breakpoint-down(sm) {
            align-items: center;
        }

        label {
            width: 100%;
            color: #fff;
            margin: 0 0 15px;
            text-transform: uppercase;
            font-size: 13px;
        }
    }

    .button--contact {
        &:hover .loading-icon:after {
            border: 5px solid #fff;
            border-color: #fff transparent #fff transparent;
        }

        .loading-icon {
            display: inline-block;
            width: 30px;
            height: 30px;
            margin: 0 0 0 15px;

            &--disable {
                display: none;
            }
        }

        .loading-icon:after {
            content: " ";
            display: block;
            width: 20px;
            height: 20px;
            margin: 0;
            padding: 0;
            border-radius: 50%;
            border: 5px solid $primary-color;
            border-color: $primary-color transparent $primary-color transparent;
            animation: loading-icon 1.2s linear infinite;
        }

        @keyframes loading-icon {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        }
    }

    &__input, &__textarea {
        color: #fff;
        background: tint($primary-color, 10%);
        border: none;
        padding: 12px;
        margin: 5px 0;
        transition: all 0.5s;
        font-size: 16px;
        width: 100%;
        box-sizing: border-box;

        &::placeholder {
            color: #ddd;
            opacity: .5;
            font-family: Montserrat, sans-serif;
        }

        &:focus {
            background: shade($primary-color, 10%);
        }
    }

    &__textarea {
        height: 100px;
        resize: vertical;
    }
}

.map {
    iframe {
        width: 100%;
        height: 450px;
        filter: invert(90%) hue-rotate(180deg);
    }
}
