.privacy-policy {
    background: #0f1012;
    color: #fff;
    position: relative;
    font-family: Montserrat, sans-serif;

    &__wrapper {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        position: relative;
        z-index: 30;
        padding: 0 0 40px;

        @include media-breakpoint-down(sm) {
            flex-direction: column;
        }
        @include media-breakpoint-between(md, lg) {
            padding: 0 20px;
            box-sizing: border-box;
        }

        h3 {
            font-size: 30px;
            width: 100%;
            margin: 30px 0 60px;
            padding: 20px 0;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;

            .line {
                width: 85%;
                height: 1px;
                position: absolute;
                background: transparentize(#fff, 0.8);
                z-index: 2;
            }

            .text {
                position: absolute;
                background: #0f1012;
                z-index: 3;
                padding: 0 20px;
            }
        }
    }

    &__content {
        h2 {
            font-size: 25px;
            margin: 30px 20px 10px;
            font-weight: 600;
        }

        h3 {
            font-size: 20px;
            text-align: left;
            display: block;
            text-transform: unset;
            margin: 10px 20px;
            padding: 0;
        }

        p {
            flex-grow: 1;
            font-size: 18px;
            line-height: 26px;
            text-align: justify;
            margin: 20px;

            a {
                color: $primary-color;
                text-decoration: none;
                font-weight: bold;

                &:hover {
                    color: shade($primary-color, 10%);
                }
            }
        }

        ul {
            list-style-type: disc;
            margin: 30px 40px;

            li {
                margin: 10px;
            }
        }
    }
}
