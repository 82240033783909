.clients {
    position: relative;
    padding: 5rem 0;
    display: flex;
    align-items: center;

    &__wrapper {
        z-index: 30;
        color: #000;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 50px 0 0;

        h3 {
            font-size: 30px;
            width: 100%;
            margin: 30px 0 60px;
            padding: 20px 0;
            font-weight: 600;
            text-transform: uppercase;
            text-align: center;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            z-index: 2;
            color: #fff;

            .line {
                width: 85%;
                height: 1px;
                position: absolute;
                background: transparentize(#000, 0.8);
                z-index: 2;
            }

            .text {
                position: absolute;
                background: #fff;
                z-index: 3;
                padding: 0 20px;
            }
        }

        .list {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-gap: 20px;

            &__item {
                width: 150px;
                height: 150px;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;
                padding: 20px;
                transition: background-color 0.5s;

                img {
                    width: 100%;
                    filter: brightness(0) invert(1);
                }

                &:nth-child(odd) {
                    background-color: #0f1012;
                }

                &:nth-child(even) {
                    background-color: transparentize(#0f1012, 0.7);
                }

                &:hover {
                    background-color: $primary-color;
                }
            }
        }
    }

    @media (max-width: 1200px) {
        &__wrapper .list {
            grid-template-columns: repeat(4, 1fr);
        }
    }
    @media (max-width: 992px) {
        &__wrapper .list {
            grid-template-columns: repeat(3, 1fr);
        }
    }
    @media (max-width: 768px) {
        &__wrapper .list {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    @media (max-width: 576px) {
        &__wrapper h3 {
            font-size: 24px;
            margin: 20px 0 40px;
        }

        &__wrapper .list__item {
            width: 120px;
            height: 120px;
        }
    }
}
