.projects {
  position: relative;
  background: $secondary-color;
  padding: 0 0 50px;

  &:before {
    position: absolute;
    top: -50px;
    content: "";
    background: $secondary-color;
    transform: skewY(3deg);
    height: 100px;
    width: 100%;
    z-index: 20;
    display: block;
  }

  &:after {
    position: absolute;
    bottom: -50px;
    content: "";
    background: $secondary-color;
    transform: skewY(3deg);
    height: 100px;
    width: 100%;
    z-index: 20;
    display: block;
  }

  &__wrapper {
    z-index: 30;
    position: relative;
    color: #fff;
    position: relative;
    display: flex;
    justify-content: space-around;

    h3 {
      font-size: 30px;
      width: 100%;
      margin: 30px 0 60px;
      padding: 20px 0;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 2;

      .line {
        width: 85%;
        height: 1px;
        position: absolute;
        background: transparentize(#fff, 0.8);
        z-index: 2;
      }

      .text {
        position: absolute;
        background: $secondary-color;
        z-index: 3;
        padding: 0 20px;
      }
    }
  }

  &__slider {
    width: 100%;
  }

  &__item {
    background: $accent-color;
    border-top: 5px solid $accent-color;
    color: #000;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    transition: background 0.5s, border 0.5s;

    @media (max-width: 576px) {
      margin: 10px 25px;
    }
    @media (min-width: 577px) and (max-width: 769px) {
      width: 100%;
      max-width: 100%;
      margin: 10px 100px;
    }
    @media (min-width: 770px) {
      width: 300px;
      max-width: 430px;
      margin: 10px;
    }

    &:hover {
      background: $primary-color;
      border-top: 5px solid $primary-color;

      h2, span {
        color: #fff;
      }

      p {
        color: #fff;

        &::-webkit-scrollbar {
            background: $primary-color;
            width: 8px;
        }
        &::-webkit-scrollbar-thumb {
            background: $secondary-color;
        }
        &::-webkit-scrollbar-corner {
            background: $primary-color;
        }
      }

      button {
        border: 2px solid #fff;
        color: $primary-color;
      }
    }

    h2 {
      font-size: 22px;
      font-weight: 600;
      text-align: center;
      padding: 0;
      margin: 30px 0 0;
      transition: font-size 0.5s;
      color: #000;
    }

    p {
      padding: 25px;
      font-weight: 400;
      line-height: 20px;
      text-align: justify;
      height: 300px;
      overflow: auto;
      margin: 0 0 10px;
      display: flex;
      align-items: center;

      &::-webkit-scrollbar {
          background: $accent-color;
          width: 8px;
      }
      &::-webkit-scrollbar-thumb {
          background: shade($accent-color, 5%);
      }
      &::-webkit-scrollbar-corner {
          background: $accent-color;
      }
    }

    span {
      margin: 15px;
      font-size: 13px;
      display: block;
      text-align: right;

      .far {
        margin: 0 5px 0;
      }
    }

    button {
      background: #fff;
      border: 2px solid $secondary-color;
      color: $secondary-color;
      font-size: 18px;
      padding: 10px 20px;
      text-transform: uppercase;
      font-weight: 600;
      margin: 20px auto;
      cursor: pointer;
      transition: background 0.5s;

      &:hover {
        background: $primary-color;
        border: 2px solid #fff;
        color: #fff;
      }
    }
  }

  &__img {
    height: 250px;
    overflow: hidden;
    background: #fff;
    padding: 20px;

    img {
      width: 100%;
    }
  }
}
